export const TOPIC_RULES: Array<{
  code: string;
  name: string;
  description: string;
}> = [
  {
    code: 'positive_intent',
    name: 'Positive Intent',
    description: 'Yes, generally agreeable',
  },
  {
    code: 'negative_intent',
    name: 'Negative Intent',
    description: 'Not interested with no particular reason',
  },
  {
    code: 'meeting_request',
    name: 'Meeting or Call request',
    description: 'Explicit interest to meet or call',
  },
  {
    code: 'ooo',
    name: 'Out of Office',
    description: 'Lead is on leave',
  },
  {
    code: 'bounced',
    name: 'Bounced',
    description: 'Lead has left the company or email address is no longer in use',
  },
  {
    code: 'referral',
    name: 'Referral',
    description:
      'Referral with email mentioned in body or CC field (auto-replies and OOO do NOT belong here)',
  },
  {
    code: 'rfi',
    name: 'Request for Information',
    description: 'Request for company, product information, pricing, etc.',
  },
  {
    code: 'neutral_intent',
    name: 'Neutral intent',
    description:
      'Emails that have no clear expression of intent e.g. gibberish, automated response emails',
  },
  {
    code: 'dnc',
    name: 'Do Not Contact',
    description: 'Lead wants to be removed from the mailing list',
  },
];
