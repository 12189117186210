import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash/fp';
import randomColor from '../../../services/random-color';
import { currentTaskSelector } from '../../../store/reducers/currentTask';
import EmailHeader from './EmailHeader';
import EmailBody from './EmailBody';

const connectToRedux = connect(state => ({
  task: currentTaskSelector(state),
}));

interface EmailProps {
  id: string;
  subject: string;
  body: string;
  ccs: string[];
  receivers: string[];
  sender: string;
}

const EmailSide: React.FC<{ task: EmailProps }> = ({ task }) => {
  if (isEmpty(task.body)) {
    return (
      <section className="EmailContainer">
        <div className="Email">
          <div className="Email__subject">
            This task is corrupted. Please copy the url and report to R&D team: task#{task.id}
          </div>
        </div>
      </section>
    );
  }

  const senderEmail = task.sender;
  const receiverEmails = task.receivers;
  // const firstReceiverEmail = receiverEmails[0];

  return (
    <section className="EmailContainer">
      <div className="Email">
        {/* <div className="Email__subject">{task.subject}</div> */}
        <EmailHeader email={task} />

        <div className="Email__body">
          {senderEmail && (
            <div className="Email__meta">
              <div className="Email__meta--avatar" style={{ background: randomColor(task.id) }}>
                <span className="initial">{senderEmail.substring(0, 1)}</span>
              </div>
              <div className="Email__meta--from-to">
                <div className="Email__meta--from">{senderEmail}</div>
                <div className="Email__meta--to">
                  <span>to: </span>
                  <span>{receiverEmails.join(', ')}</span>
                </div>
                {!isEmpty(task.ccs) && (
                  <div className="Email__meta--ccs">
                    <span>cc: </span>
                    <span>{task.ccs.join(', ')}</span>
                  </div>
                )}
              </div>
            </div>
          )}
          {/* <div className="Email__content">{cleanBody}</div> */}
          <EmailBody email={task} />
        </div>
      </div>
    </section>
  );
};

export default connectToRedux(EmailSide);
