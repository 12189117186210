import { branch, renderComponent } from 'recompose';
import { identity, isEmpty } from 'lodash/fp';

const waitingProps = (LoadingComponent: React.FC) =>
  branch(
    ({ requiredProps }: { requiredProps: any }) => isEmpty(requiredProps),
    renderComponent(LoadingComponent),
    identity
  );

export default waitingProps;
