import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle, setDisplayName } from 'recompose';
import withState from 'recompose/withState';

import NavBar from '../../components/NavBar';
import KeyboardShortcutModal from '../../components/KeyboardShortcutModal';
import Loading from '../../components/Loading';

import { getAnnotationTask } from '../../services/apiCalls/task';
import requireUser from '../../services/HOC/requireUser';

const connectToRedux = connect(
  null, // no state required
  {
    onPoll: getAnnotationTask, // on poll, get annotation task
  }
);

const withTimer = withState('timerId', 'setTimer', null);

const poll = lifecycle<
  { onPoll: Function, setTimer: Function, timerId: any }, {}
>({
  componentDidMount() {
    this.props.setTimer(setInterval(this.props.onPoll, 60000));
  },
  componentWillUnmount() {
    clearInterval(this.props.timerId);
  },
});

export interface PendingProps {
  onPoll: Function;
}

const enhance = compose<PendingProps, {}>(
  setDisplayName('pagePending'),
  requireUser,
  connectToRedux,
  withTimer,
  poll
);

const Pending = () => (
  <>
    <NavBar key="navbar" />
    <KeyboardShortcutModal key="modal" />
    <div className="container" data-testid="Dashboard">
      <div className="PendingContainer">
        <div className="title">No annotation tasks!</div>
        <div className="subtitle">
          Helping you fetch new tasks if available...
        </div>
        <Loading />
      </div>
    </div>
  </>
);

export default enhance(Pending);
