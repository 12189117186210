import React from 'react';
import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';
import { pathOr } from 'lodash/fp';
import inflection from 'inflection';

import bgDashboardPng from '../../assets/images/bg-dashboard.png';
import NavBar from '../../components/NavBar';
import Button from '../../components/Button';

import { getAnnotationTask } from '../../services/apiCalls/task';
import requireUser from '../../services/HOC/requireUser';
import { LooseObject } from '../../types/basic';
import { currentUserEmailSelector } from '../../store/reducers/currentUser';

const connectToRedux = connect(
  (state: any) => ({
    currentUserEmail: currentUserEmailSelector(state),
  }),
  {
    onStart: getAnnotationTask,
  }
);

export interface DashboardProps {
  requireProps: LooseObject;
  onStart: Function;
  currentUserEmail: string;
}

const enhance = compose<DashboardProps, {}>(
  setDisplayName('pageDashboard'),
  requireUser,
  connectToRedux
);

const Dashboard = ({
  onStart,
  currentUserEmail,
}: {
  onStart: Function;
  currentUserEmail: string;
}) => {
  const username = pathOr('', '[1]', currentUserEmail.match(/^(.*)@.*/));

  return (
    <>
      <NavBar key="navbar" />
      <div className="container" data-testid="Dashboard">
        <div className="WelcomeDescriptionContainer">
          <div className="WelcomeDescription">
            <div className="title">Howdy, {inflection.capitalize(username)}!</div>
            <div className="subtitle">
              Welcome to Saleswhale Operator! By joining Saleswhale, you are now part of a very
              special family seeking to rapidly build a world-class human-in-the-loop AI sales
              assistant that serves hundreds of delighted customers world-wide. As an Operator, you
              help to run our core business logic by qualifying replies, thereby generating examples
              for the AI to train on. Ready?
            </div>
            <Button className="btn--session" type="button" status="continue" onClick={onStart}>
              Start your session
            </Button>
          </div>
        </div>
        <div className="WelcomeImageContainer">
          <div className="WelcomeImage">
            <img className="img" src={bgDashboardPng} alt="dashboard-session" width="574px" />
          </div>
        </div>
      </div>
    </>
  );
};

export default enhance(Dashboard);
