import { addHours } from 'date-fns';
import { path, pathOr } from 'lodash/fp';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { AnyAction } from 'redux';
import { SUBMIT_TASK } from './currentTask';

export const LOGIN = '@LOGIN';
export const LOGOUT = '@LOGOUT';
export const TOGGLE_SHOWMORE = '@TOGGLE_SHOWMORE';

export const currentUserSelector = path('currentUser');
export const currentUserEmailSelector = pathOr('', 'currentUser.email');
export const currentUserNameSelector = path('currentUser.name');
export const currentUserYesterdayTasksSelector = path('currentUser.yesterdayTasks');
export const currentUserTokenSelector = path('currentUser.meta.token');
export const currentUserNumberOfProcessedTasksSelector: (any: any) => number = pathOr(
  0,
  'currentUser.numberOfProcessedTasks'
);

export interface IUser {
  id: string;
  email: string;
  name: string;
  yesterdayTasks: number;
  exp?: string | Date;
}

export const loginUser = (user: IUser) => ({
  type: LOGIN,
  payload: user,
});

export const logoutUser = () => ({
  type: LOGOUT,
});

interface UserState {
  exp?: string | Date;
  numberOfProcessedTasks?: number;
}
/* istanbul ignore next */
const reducer = (state: UserState = {}, { type, payload }: AnyAction) => {
  switch (type) {
    case LOGIN:
      return { ...state, ...payload, exp: addHours(new Date(), 2) };
    case SUBMIT_TASK: {
      const numberOfTask = state.numberOfProcessedTasks || 0;
      return { ...state, numberOfProcessedTasks: numberOfTask + 1 };
    }
    case LOGOUT:
      return {};
    default:
      return state;
  }
};

const persistConfig = { key: 'currentUser', storage };

export default { currentUser: persistReducer(persistConfig, reducer) };
