import React from 'react';
import { connect } from 'react-redux';
import { InjectedFormProps, reduxForm, Field } from 'redux-form';
import { compose, withProps, setDisplayName } from 'recompose';

import logoInvertedPng from '../../assets/images/logo-inverted.png';
import emailPng from '../../assets/images/email.png';
import passwordPng from '../../assets/images/password.png';
import Button from '../../components/Button';
import requireGuest from '../../services/HOC/requireGuest';

import withLadda, { LaddaProps } from '../../services/HOC/withLadda';
import sequence from '../../services/sequence';
import { postLoginUser } from '../../services/apiCalls/user';

const connectToRedux = connect(
  null,
  { onLogin: postLoginUser }
);

const withSubmitAction = withProps(
  ({ startLoading, stopLoading, onLogin }: { onLogin: Function } & LaddaProps) => ({
    onSubmit: sequence(startLoading, onLogin, stopLoading),
  })
);

const enhance = compose<LaddaProps & InjectedFormProps<LoginFormField>, {}>(
  setDisplayName('pageLogin'),
  requireGuest,
  connectToRedux,
  withLadda,
  withSubmitAction,
  reduxForm({ form: 'login' })
);

interface LoginFormField {
  email: string;
  password: string;
}

const Login: React.FC<InjectedFormProps<LoginFormField> & { isLoading: boolean }> = ({
  handleSubmit,
  isLoading,
}) => (
  <div className="LoginContainer">
    <img className="img--lg" src={logoInvertedPng} alt="logo" />

    <form
      className="LoginForm"
      onSubmit={handleSubmit}
      data-loading={isLoading}
      data-testid="login-form"
    >
      <span className="LoginForm__title">Sign in to Operator</span>
      <div className="LoginForm__field">
        <img className="img--sm" src={emailPng} alt="email" />
        <Field
          name="email"
          component="input"
          type="email"
          placeholder="Email address"
          required
          autoFocus
        />
      </div>
      <div className="LoginForm__field">
        <img className="img--sm" src={passwordPng} alt="password" />
        <Field name="password" component="input" type="password" placeholder="Password" required />
      </div>
      <Button type="submit" className="btn--login">
        Login
      </Button>
    </form>

    <div className="footer neutral-color">
      <p>
        <span>Copyright &copy; 2020 </span>
        <a
          className="footer--white"
          href="https://saleswhale.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Saleswhale Inc.
        </a>
        <span> All Rights Reserved.&nbsp;</span>
        <a
          className="footer--white"
          href="https://saleswhale.com/privacy_policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy
        </a>
        <span> and </span>
        <a
          className="footer--white"
          href="https://saleswhale.com/terms_of_service"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms
        </a>
      </p>
    </div>
  </div>
);

export default enhance(Login);
