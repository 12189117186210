import React from 'react';
import classnames from 'classnames';

interface IRule {
  index: number;
  rule: {
    code: string;
    name: string;
    description: string;
  };
  onSelect: () => {};
  isSelected: boolean;
}

const Rule: React.FC<IRule> = ({ rule, onSelect, isSelected }) => (
  <div
    id={`rule-${rule.code}`}
    data-testid={`rule-${rule.code}`}
    className={classnames('TopicRule', { active: isSelected })}
    onClick={onSelect}
  >
    <div className={classnames('TopicRule__icon', `TopicRule__icon--${rule.code}`)} />
    <div className="TopicRule__information">
      <div className="TopicRule__name">{rule.name}</div>
      <div className="TopicRule__description">{rule.description}</div>
    </div>
    <div
      className={classnames({
        'TopicRule--active': isSelected,
        'TopicRule--inactive': !isSelected,
      })}
    />
  </div>
);

export default Rule;
