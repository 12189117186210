import { path } from 'lodash/fp';
import { AnyAction } from 'redux';

export const SET_TASK = '@TASK/SET';
export const SUBMIT_TASK = '@TASK/SUBMIT';
export const SELECT_RULE = '@TASK/SELECT_RULE';

export const currentTaskSelector = path('currentTask');
export const currentTaskIdSelector = path('currentTask.id');
export const currentSelectedRuleCodeSelector = path('currentTask.selectedRuleCode');

export interface ITask {
  id: string;
  subject: string;
  body: string;
  ccs: string[];
  receivers: string[];
  sender: string;
}

export const setTask = (task: ITask) => ({
  type: SET_TASK,
  payload: task,
});

export const selectRule = (ruleCode: string) => ({
  type: SELECT_RULE,
  payload: ruleCode,
});

export const submitTask = () => ({ type: SUBMIT_TASK });

/* istanbul ignore next */
const currentTask = (state = {}, { type, payload }: AnyAction) => {
  switch (type) {
    case SET_TASK:
      return payload;
    case SELECT_RULE:
      return { ...state, selectedRuleCode: payload };
    case SUBMIT_TASK:
      return {};
    default:
      return state;
  }
};

export default { currentTask };
