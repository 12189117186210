import { AnyAction, MiddlewareAPI, Dispatch } from 'redux';

export default (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction | AnyAction[]) => {
  if (Array.isArray(action)) {
    action.forEach(store.dispatch);
    return;
  }

  next(action);
};
