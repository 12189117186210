import React from 'react';
import { setDisplayName } from 'recompose';
import { IHasChildren, LooseObject } from '../../types/basic';

interface Dialog extends IHasChildren {
  testId?: string;
  title?: string;
  className?: string;
  style?: LooseObject;
  bodyStyle?: LooseObject;
  onRequestClose: any;
}

const Dialog: React.FC<Dialog> = ({
  testId,
  title,
  className,
  style = {},
  bodyStyle = {},
  children,
  onRequestClose,
}) => (
  <div className={className} tabIndex={-1} role="dialog" style={style} data-testid={testId}>
    <div className="md-content">
      <div className="md-title">
        <span>{title}</span>
        <span onClick={onRequestClose} style={{ cursor: 'pointer' }}>
          ✖
        </span>
      </div>
      <div className="md-body">
        <div style={bodyStyle}>{children}</div>
      </div>
    </div>
  </div>
);

export default setDisplayName('Dialog')(Dialog);
