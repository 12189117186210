import React from 'react';

interface EmailBodyProps {
  email: {
    body: string;
  };
}

const EmailBody: React.FC<EmailBodyProps> = ({ email: { body } }) => {
  const cleanBody = body
    .split('\n')
    .filter(
      sentence =>
        !sentence.match(/^Sender.*/) &&
        !sentence.match(/^To.*/) &&
        !sentence.match(/^CC: .*/) &&  //  !sentence.match(new RegExp(`^On.*<\\s?${firstReceiverEmail}\\s?>.*`, 'g')) &&
        !sentence.match(/^wrote:/) &&
        !sentence.match(/^>.*/) &&
        !sentence.match(/^&gt;.*/)
    )
    .join('\n')
    .trim();

  return (
    <div className="Email__body">
      <div className="Email__content" dangerouslySetInnerHTML={{ __html: cleanBody }} />
    </div>
  );
};

export default EmailBody;
