import { post } from '../fetch';
import { loginUser, logoutUser } from '../../store/reducers/currentUser';
import { history, getRoute } from '../router';

import { MyThunkResult } from '../../types/thunk';
import { toastError, toastSuccess } from '../toastify';

type LoginRequest = ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => MyThunkResult<Promise<any>>;

export const postLoginUser: LoginRequest = ({ email, password }) => dispatch => {
  return dispatch(post('/token', { email, password }))
    .then((user: any) => dispatch(loginUser(user)))
    .then(() => history.push(getRoute('dashboard')))
    .then(() => toastSuccess('Successfully signed in'))
    .catch(() => toastError('Authentication failed. Please try again'));
};

type LogoutRequest = () => MyThunkResult<Promise<any>>;

export const postLogoutUser: LogoutRequest = () => async dispatch => {
  await dispatch(logoutUser());
  toastSuccess('Successfully signed out');

  return history.push(getRoute('login'));
};
