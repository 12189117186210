import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'react-router-dom';
import { Persistor } from 'redux-persist';
import { Store } from 'redux';

import AppRoute from './AppRoute';
import Loading from './components/Loading';

import { history } from './services/router';

const Root: React.FC<{
  store: Store;
  persistor: Persistor;
}> = ({ store, persistor }) => (
  <Provider store={store}>
    <PersistGate loading={<Loading />} persistor={persistor}>
      <Router history={history}>
        <main className="App">
          <AppRoute />
        </main>
      </Router>
    </PersistGate>
  </Provider>
);

export default Root;
