import React from 'react';

const Loading = () => (
  <div className="container loading" data-testid="loading">
    <div className="spinner--on">
      <div className="spinner__innerBounce" />
      <div className="spinner__outerBounce" />
    </div>
  </div>
);

export default Loading;
