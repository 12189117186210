import React from 'react';
import classnames from 'classnames';
import { IHasChildren } from '../types/basic';

interface IButton extends IHasChildren {
  type?: string;
  className?: string;
  status?: string;
  children: React.ReactNode;
  [otherProp: string]: any;
}

const Button: React.FC<IButton> = (props: any) => (
  <button {...props} className={classnames(props.className, `btn--${props.status}`)}>
    <span>{props.children}</span>
  </button>
);

export default Button;
