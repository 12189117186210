import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import compact from 'lodash/fp/compact';
import { createLogger } from 'redux-logger';
import { persistStore } from 'redux-persist';

import { createReducer } from './rootReducer';
import arrayMiddleware from './middlewares/arrayMiddleware';

const loggerMiddleware = createLogger({
  level: 'info',
  collapsed: true,
});

const composeEnhancers =
  process.env.NODE_ENV === 'development' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ name: 'OVERMIND' })
    : compose;

const middlewares = [thunk, arrayMiddleware];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(loggerMiddleware);
}

const enhancers = compact([applyMiddleware(...middlewares)]);

export default (initialState = {}) => {
  const rootReducer = createReducer();
  const store = createStore(rootReducer, initialState, composeEnhancers(...enhancers));
  const persistor = persistStore(store);

  if ((module as any).hot) {
    (module as any).hot.accept('./rootReducer', () => {
      // eslint-disable-next-line global-require
      const createNextReducer = require('./rootReducer').default;
      const nextRootReducer = createNextReducer();
      store.replaceReducer(nextRootReducer);
      persistor.persist();
    });
  }

  return { store, persistor };
};
