import { LooseObject } from '../types/basic';

const routerConfig: LooseObject = {
  root: '/',
  login: '/login',
  dashboard: '/dashboard',
  pending: '/pending',
  task: '/tasks/:taskId',
};

export default routerConfig;
