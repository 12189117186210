const EFFECTS = [
  'fadeAndScale',
  'slideInRight',
  'slideInBottom',
  'newspaper',
  'fall',
  'sideFall',
  'stickyUp',
  '3DFlipHoriz',
  '3DFlipVert',
  '3DSign',
  'superScaled',
  'justMe',
  '3DSplit',
  '3DRotateBottom',
  '3DRotateLeft',
  'blur',
];

export const getEffectClassName = (name: string) => `md-effect-${EFFECTS.indexOf(name) + 1}`;
