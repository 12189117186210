import React from 'react';
import Rule from '../../../components/Rule';

export interface RuleListProps {
  rules: Array<{
    code: string;
    name: string;
    description: string;
  }>;
  onSelect: Function;
  currentSelectedRuleCode?: string;
  shownMore?: boolean;
  onShowMore?: any;
}

const RuleList: React.FC<RuleListProps> = ({
  rules,
  onSelect,
  currentSelectedRuleCode,
  shownMore,
  onShowMore,
}) => {
  const shownRules = shownMore ? rules : rules.slice(0, 5);

  return (
    <>
      {shownRules.map((rule, index) => (
        <Rule
          key={rule.code}
          index={index}
          rule={rule}
          onSelect={() => onSelect(rule.code)}
          isSelected={rule.code === currentSelectedRuleCode}
        />
      ))}
      {!shownMore && (
        <div className="more" onClick={onShowMore} data-testid="show-more">
          Show More Rules
        </div>
      )}
    </>
  );
};

export default RuleList;
