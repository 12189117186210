import React from 'react';
import { connect } from 'react-redux';
import { path } from 'lodash/fp';
import logoInvertedPng from '../assets/images/logo-inverted.png';
import caretSvg from '../assets/images/caret.svg';
import statShiftPng from '../assets/images/stat-shift.png';
import statYesterdayPng from '../assets/images/stat-yesterday.png';

import { postLogoutUser } from '../services/apiCalls/user';
import {
  currentUserNumberOfProcessedTasksSelector,
  currentUserSelector,
} from '../store/reducers/currentUser';

const enhance = connect(
  state => ({
    currentUser: currentUserSelector(state),
    numberOfProcessedTasks: currentUserNumberOfProcessedTasksSelector(state),
  }),
  {
    onLogout: postLogoutUser,
  }
);

interface INavBar {
  currentUser: {
    id: string;
    email: string;
    name: string;
    yesterdayTasks: number;
  };
  numberOfProcessedTasks: number;
  onToggleModal?: () => {};
  onLogout: () => {};
}

export const PureNavBar: React.FC<INavBar> = ({
  currentUser,
  numberOfProcessedTasks,
  onLogout,
}) => {
  const email = currentUser.email || '';
  const username = path('[1]', email.match(/^(.*)@/));
  return (
    <nav className="Navbar" data-testid="navbar">
      <div className="Navbar__title">OPERATOR</div>
      <div className="Navbar__logo">
        <img className="img--md" src={logoInvertedPng} alt="logo" />
      </div>
      <div className="Navbar--collapse">
        <ul className="NavItem">
          <li className="NavItem__menu">
            <div className="NavLink">
              <div className="Avatar">
                <span className="Avatar__initial">{email.substring(0, 1)}</span>
              </div>

              <div className="NavLink__name">
                <span>{username}</span>
                <span className="NavLink__name--sub">{numberOfProcessedTasks} tasks completed</span>
              </div>
              <img className="img--xs" src={caretSvg} alt="caret" />
            </div>

            <div className="NavItem__submenu">
              <div className="item item--stat">
                <div className="stat__header">Your Stats</div>
                <div className="stat__description">
                  <div className="stat__today">
                    <img className="img" src={statShiftPng} alt="stat-shift" />
                    <div className="stat__info">
                      <span className="stat__number">{numberOfProcessedTasks}</span>
                      <span className="stat__day">This shift</span>
                    </div>
                  </div>
                  <div className="stat__yesterday">
                    <img className="img" src={statYesterdayPng} alt="stat-yesterday" />
                    <div className="stat__info">
                      <span className="stat__number">{currentUser.yesterdayTasks}</span>
                      <span className="stat__day">Yesterday</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item" onClick={onLogout}>
                Sign out
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default enhance(PureNavBar);
