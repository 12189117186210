import React from 'react';

interface EmailHeaderProps {
  email: {
    subject: string;
  };
}

const EmailHeader: React.FC<EmailHeaderProps> = ({ email: { subject } }) => {
  return <div className="Email__subject">{subject}</div>;
};

export default EmailHeader;
