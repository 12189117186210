import { get, post } from '../fetch';
import {
  ITask,
  setTask,
  submitTask,
  currentTaskIdSelector,
  currentSelectedRuleCodeSelector,
} from '../../store/reducers/currentTask';
import { history, getRoute } from '../router';

import { MyThunkResult } from '../../types/thunk';
import { toastError } from '../toastify';

type AnnotationTaskRequest = () => MyThunkResult<Promise<any>>;
type AnnotationTaskRequestWithId = (id: string) => MyThunkResult<Promise<any>>;

export const getAnnotationTask: AnnotationTaskRequest = () => dispatch => {
  return dispatch(get('/task', { namespace: '/operator_api/v1' }))
    .then((task: ITask) => {
      dispatch(setTask(task));
      history.replace(getRoute('task', { taskId: task.id }));
    }).catch(() => history.replace(getRoute('pending')));
};

export const getAnnotationTaskWithId: AnnotationTaskRequestWithId = id => dispatch => {
  return dispatch(get(`/tasks/${id}`, { namespace: '/operator_api/v1' }))
    .then((task: ITask) => dispatch(setTask(task)))
    .catch(({ error }) => toastError(error));
};

export const postSubmitTask: AnnotationTaskRequest = () => (dispatch, getState) => {
  const state = getState();
  const topicRule = currentSelectedRuleCodeSelector(state);
  const taskId = currentTaskIdSelector(state);

  return dispatch(post('/predictions', { taskId, topicRule, namespace: '/operator_api/v1' }))
    .then(() => dispatch(submitTask()))
    .catch(({ error }) => toastError(error));
};

export const postSkipTask: AnnotationTaskRequest = () => (dispatch, getState) => {
  const state = getState();
  const taskId = currentTaskIdSelector(state);

  return dispatch(post(`/task/${taskId}/skip`, { namespace: '/operator_api/v1' }))
    .then(() => dispatch(submitTask()))
    .catch(({ error }) => toastError(error));
};
