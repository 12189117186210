import { combineReducers } from 'redux';

import { reducer as formReducer } from 'redux-form';
import setup from './reducers/setup';
import currentUser from './reducers/currentUser';
import currentTask from './reducers/currentTask';

export const createReducer = () =>
  combineReducers({
    form: formReducer,
    ...setup,
    ...currentUser,
    ...currentTask,
  });

export default createReducer;
