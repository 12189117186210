import React from 'react';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import defaultProps from 'recompose/defaultProps';
import withState from 'recompose/withState';
import setDisplayName from 'recompose/setDisplayName';
import classNames from 'classnames';
import Dialog from './Dialog';
import { getEffectClassName } from './effects';
import { LooseObject } from '../../types/basic';

const withCycle = lifecycle<
  { transitionDuration: number; open: boolean; setRendered: Function; setVisible: Function },
  {}
>({
  componentDidMount() {
    if (this.props.open) {
      this.props.setRendered(true);
      this.props.setVisible(true);
    }
  },
  componentDidUpdate(prevProps) {
    if (this.props.open === prevProps.open) {
      return;
    }

    if (this.props.open) {
      this.props.setRendered(true);
      setTimeout(() => {
        this.props.setVisible(true);
      }, 10);
      return;
    }

    prevProps.setVisible(false);
    setTimeout(() => {
      prevProps.setRendered(false);
    }, this.props.transitionDuration);
  },
});

const withRendered = withState('rendered', 'setRendered', false);
const withVisible = withState('visible', 'setVisible', false);

const enhance = compose<
  {
    title?: string;
    onRequestClose: any;
    effectName: string;
    className?: string;
    visible: boolean;
    rendered: boolean;
    style?: LooseObject;
    bodyStyle: LooseObject;
    children: React.ReactNode;
  },
  {
    testId?: string;
    title: string;
    open: boolean;
    onRequestClose: any;
    transitionDuration?: number;
    effectName: string;
    className?: string;
    style?: LooseObject;
  }
>(
  setDisplayName('Modal'),
  defaultProps({
    transitionDuration: 300,
    effectName: 'fadeAndScale',
  }),
  withRendered,
  withVisible,
  withCycle
);

interface Modal {
  testId?: string;
  title?: string;
  effectName: string;
  className?: string;
  onRequestClose: any;
  visible: boolean;
  rendered: boolean;
  style?: LooseObject;
  bodyStyle: LooseObject;
  children: React.ReactNode;
}

const Modal: React.FC<Modal> = ({
  testId,
  title,
  effectName,
  className,
  onRequestClose,
  visible,
  rendered,
  style,
  bodyStyle,
  children,
}) => {
  const dialog = (
    <Dialog
      key="dialog"
      testId={testId}
      title={title}
      className={classNames(className, 'md-modal', getEffectClassName(effectName), {
        'md-show': visible,
      })}
      style={style}
      bodyStyle={bodyStyle}
      onRequestClose={onRequestClose}
    >
      {rendered && children}
    </Dialog>
  );

  const backdrop = <div key="backdrop" className="md-overlay" onClick={onRequestClose} />;

  return (
    <div>
      {rendered && dialog}
      {backdrop}
    </div>
  );
};

export default enhance(Modal);
