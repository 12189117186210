import { compose, withState, withProps } from 'recompose';

const withIsLoadingState = withState('isLoading', 'setIsLoading', false);

const withBeginStopLoading = withProps(({ setIsLoading }: { setIsLoading: Function }) => ({
  startLoading: (anything: any): Promise<any> => {
    setIsLoading(true);
    return Promise.resolve(anything);
  },
  stopLoading: (anything: any): Promise<any> => {
    setIsLoading(false);
    return Promise.resolve(anything);
  },
}));

export interface LaddaProps {
  isLoading: boolean;
  startLoading: (x: any) => Promise<any>;
  stopLoading: (y: any) => Promise<any>;
}

const withLadda = compose<LaddaProps, {}>(
  withIsLoadingState,
  withBeginStopLoading
);

export default withLadda;
