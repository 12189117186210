import inflection from 'inflection';
import { flow, path as pathFP } from 'lodash/fp';
import simpleJsonApiNormalize from 'simple-json-api-normalize';

import { LooseObject } from '../types/basic';
import { MyThunkResult } from '../types/thunk';

export function parseJSON(response: { status: number; json: Function }) {
  const json = response.json();

  if (response.status >= 200 && response.status < 300) {
    return json;
  }
  return json.then((err: string | Error | Object) => Promise.reject(err));
}

function isArray(value: any): value is any[] {
  return Array.isArray(value);
}

export function isObject(value: any): value is LooseObject {
  return value != null && typeof value === 'object';
}

export function isDate(value: any) {
  return value instanceof Date;
}

export function normalize(data: Array<any> | LooseObject): Array<any> | LooseObject {
  if (isArray(data)) {
    return data.map((value: any) => (isObject(value) ? normalize(value) : value));
  }
  // eslint-disable-next-line
  let results: LooseObject = {};
  Object.keys(data).forEach(key => {
    const value = data[key];
    const nv = isObject(value) ? normalize(value) : value;
    results[inflection.camelize(key, true)] = nv;
  });
  return results;
}

export function denormalize(data: Array<any> | LooseObject): Array<any> | LooseObject {
  if (isArray(data)) {
    return data.map(value => (isObject(value) ? denormalize(value) : value));
  }
  // eslint-disable-next-line
  let results: LooseObject = {};
  Object.keys(data).forEach(key => {
    const value = data[key];
    // eslint-disable-next-line
    let dnv = value;
    if (isDate(value)) {
      dnv = value.toJSON();
    } else if (isObject(value)) {
      dnv = denormalize(value);
    }
    results[underscoreKey(key)] = dnv;
  });
  return results;
}

function underscoreKey(key: string): string {
  // eslint-disable-next-line
  let udkey: string = key;
  udkey = inflection.underscore(key);
  if (key.startsWith('_')) udkey = `_${udkey}`;

  return udkey;
}

type IRequest = (path: string, params?: LooseObject) => MyThunkResult<Promise<any>>;

export const request: IRequest = (path, params = {}) => (_, getState) => {
  const state = getState();
  const currentUserToken = pathFP('currentUser.meta.token', state);
  // eslint-disable-next-line
  params.headers = {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${currentUserToken}`,
    ...(params.headers || {}),
  };
  const { namespace = '', ...restParams } = params;

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}${namespace}${path}`, restParams)
    .then(parseJSON)
    .then(
      flow(
        normalize,
        simpleJsonApiNormalize
      )
    );
};

export const get: IRequest = (path, params = {}) => {
  const { namespace, ...restParams } = params;
  const denormalizeParams: LooseObject = denormalize(restParams);
  const body = Object.keys(denormalizeParams)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(denormalizeParams[key])}`)
    .join('&')
    .replace(/%20/g, '+');
  const url = body ? `${path}?${body}` : path;

  return request(url, { method: 'GET', namespace });
};

export const post: IRequest = (path, params = {}) => {
  const { namespace, ...restParams } = params;
  const body = JSON.stringify(denormalize(restParams));

  return request(path, { method: 'POST', body, namespace });
};

export const patch: IRequest = (path, params = {}) => {
  const { namespace, ...restParams } = params;
  const body = JSON.stringify(denormalize(restParams));

  return request(path, { method: 'PATCH', body, namespace });
};
