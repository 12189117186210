import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';
import { isBefore } from 'date-fns';

import { currentUserSelector } from '../../store/reducers/currentUser';
import { postLogoutUser } from '../apiCalls/user';
import { LooseObject } from '../../types/basic';

const connectToRedux = connect(
  state => ({
    currentUser: currentUserSelector(state),
  }),
  { logout: postLogoutUser }
);

interface AnyComponentHaveCurrentUser {
  currentUser: LooseObject;
  [x: string]: any;
}

const requireUser = (InputComponent: React.FC) =>
  class _ extends Component<AnyComponentHaveCurrentUser> {
    componentDidMount() {
      const { meta = {}, exp } = this.props.currentUser;
      const authorized = meta.token ? isBefore(new Date(), new Date(exp)) : false;
      if (!authorized) {
        this.props.logout();
      }
    }

    render() {
      return <InputComponent {...this.props} />;
    }
  };

export default compose(
  setDisplayName('requireUser'),
  connectToRedux,
  requireUser
);
