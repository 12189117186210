import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { toast, Slide } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';

import { getRoute } from './services/router';

import Home from './pages/Home';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Task from './pages/Task';
import Pending from './pages/Pending';
import { LooseObject } from './types/basic';

toast.configure({
  position: 'top-center',
  transition: Slide,
  autoClose: 1500,
  draggable: false,
  hideProgressBar: true,
  newestOnTop: true,
  closeOnClick: true,
  className: 'CustomToastContainer',
  toastClassName: 'CustomToast CustomToast--success',
  bodyClassName: 'CustomToastBody CustomToastBody--success',
});

const AppRoute = () => (
  <Switch key="routes">
    <Route exact path={getRoute('root')} component={Home} />
    <Route path={getRoute('login')} component={Login} />
    <Route path={getRoute('dashboard')} component={Dashboard} />
    <Route
      path={getRoute('task')}
      component={({ match }: { match: LooseObject }) => <Task taskId={match.params.taskId} />}
    />
    <Route path={getRoute('pending')} component={Pending} />
  </Switch>
);

export default AppRoute;
