/* istanbul ignore next */
const randomColor = (id: string) => {
  const numeric = parseInt(id, 10);

  switch (numeric % 10) {
    case 0:
      return '#30c0b5';
    case 1:
      return '#fb555a';
    case 2:
      return '#e12eac';
    case 3:
      return '#914f33';
    case 4:
      return '#1955bf';
    case 5:
      return '#cdcac4';
    case 6:
      return '#4fa718';
    case 7:
      return '#622694';
    case 8:
      return '#c38446';
    case 9:
      return '#93a607';
    default:
      return '#30c0b5';
  }
};

export default randomColor;
