import { createBrowserHistory } from 'history';
import routerConfig from '../config/routerConfig';
import { LooseObject } from '../types/basic';

const history = createBrowserHistory();

function getRoute(name: string, pathParams: LooseObject = {}, searchParams: LooseObject = {}) {
  // eslint-disable-next-line
  let router = routerConfig[name];

  Object.keys(pathParams).forEach(key => {
    router = router.replace(new RegExp(`:${key}`), pathParams[key]);
  });

  const searchBody = Object.keys(searchParams)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(searchParams[key])}`)
    .join('&')
    .replace(/%20/g, '+');

  if (searchBody) {
    router = `${router}?${searchBody}`;
  }

  return router;
}

export { history, getRoute };
