import { toast } from 'react-toastify';

export const toastSuccess = (successMessage: string) =>
  toast(successMessage, {
    className: 'CustomToast CustomToast--success',
    bodyClassName: 'CustomToastBody CustomToastBody--success',
  });

export const toastWarning = (warningMessage: string) =>
  toast(warningMessage, {
    className: 'CustomToast CustomToast--warning',
    bodyClassName: 'CustomToastBody CustomToastBody--warning',
  });

export const toastInfo = (infoMessage: string) =>
  toast(infoMessage, {
    className: 'CustomToast CustomToast--info',
    bodyClassName: 'CustomToastBody CustomToastBody--info',
  });

export const toastError = (errorMessage: string) =>
  toast(errorMessage, {
    className: 'CustomToast CustomToast--error',
    bodyClassName: 'CustomToastBody CustomToastBody--error',
  });
