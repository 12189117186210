import React from 'react';
import { connect } from 'react-redux';

import Button from '../../../components/Button';
import RuleList from './RuleList';

import { currentSelectedRuleCodeSelector, selectRule } from '../../../store/reducers/currentTask';
import { TOPIC_RULES } from '../../../store/reducers/topicRules';
import {
  toggleShowKeyboardShortcut,
  showMore,
  shownMoreSelector,
} from '../../../store/reducers/setup';
import { getAnnotationTask, postSubmitTask, postSkipTask } from '../../../services/apiCalls/task';
import sequence from '../../../services/sequence';

const connectToRedux = connect(
  state => ({
    rules: TOPIC_RULES,
    shownMore: shownMoreSelector(state),
    currentSelectedRuleCode: currentSelectedRuleCodeSelector(state),
  }),
  {
    onSelect: selectRule,
    onShowModal: toggleShowKeyboardShortcut,
    onShowMore: showMore,
    getAnnotationTask,
    postSubmitTask,
    postSkipTask,
  },
  (
    stateProps,
    // eslint-disable-next-line no-shadow
    { getAnnotationTask, postSubmitTask, postSkipTask, ...dispatchProps },
    ownProps
  ) => ({
    ...stateProps,
    ...ownProps,
    ...dispatchProps,
    onSubmit: sequence(postSubmitTask, getAnnotationTask),
    onNext: sequence(postSkipTask, getAnnotationTask),
  })
);

interface AnnotationSideProps {
  rules: Array<{
    code: string;
    name: string;
    description: string;
  }>;
  currentSelectedRuleCode: string;
  shownMore: boolean;
  onSelect: Function;
  onSubmit: Function;
  onShowModal: Function;
  onShowMore: Function;
  onNext: Function;
}

const AnnotationSide: React.FC<AnnotationSideProps> = ({
  rules,
  currentSelectedRuleCode,
  onSelect,
  onSubmit,
  onNext,
  onShowMore,
}) => (
  <>
    <div key="annotation" className="AnnotationContainer">
      <div className="Annotation">
        <div className="Annotation__header">
          <div className="title">Select a topic rule</div>
        </div>
        <form className="Annotation__topicRules">
          <RuleList
            rules={rules}
            currentSelectedRuleCode={currentSelectedRuleCode}
            onSelect={onSelect}
            shownMore={true}
            onShowMore={onShowMore}
          />
          <div
            style={{
              position: 'fixed',
              zIndex: 100,
              bottom: '5vh',
            }}
          >
            <Button type="button" status="skip" onClick={onNext}>
              Skip
            </Button>
            <Button
              type="button"
              status={currentSelectedRuleCode ? 'continue' : 'disabled'}
              disabled={!currentSelectedRuleCode}
              onClick={onSubmit}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>
  </>
);

export default connectToRedux(AnnotationSide);
