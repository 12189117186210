import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';
import { isBefore } from 'date-fns';

import { history, getRoute } from '../router';
import { currentUserSelector } from '../../store/reducers/currentUser';
import { LooseObject } from '../../types/basic';

const connectToRedux = connect(state => ({
  currentUser: currentUserSelector(state),
}));

interface AnyComponentHaveCurrentUser {
  currentUser: LooseObject;
  [x: string]: any;
}

const requireGuest = (InputComponent: React.FC) =>
  class _ extends Component<AnyComponentHaveCurrentUser> {
    componentDidMount() {
      const { meta = {}, exp } = this.props.currentUser;
      const authorized = meta.token ? isBefore(new Date(), new Date(exp)) : false;
      if (authorized) {
        history.push(getRoute('dashboard'));
      }
    }

    render() {
      return <InputComponent {...this.props} />;
    }
  };

export default compose(
  setDisplayName('requireGuest'),
  connectToRedux,
  requireGuest
);
