import { path } from 'lodash/fp';
import { AnyAction } from 'redux';

import { SUBMIT_TASK } from './currentTask';

export const TOGGLE_KEYBOARD_SHORTCUT = '@TOGGLE_KEYBOARD_SHORTCUT';
export const SHOW_KEYBOARD_SHORTCUT = '@SHOW_KEYBOARD_SHORTCUT';
export const SHOW_MORE = '@SHOW_MORE';

export const setupSelector = path('setup');
export const shownKeyboardShortcutSelector = path('setup.shownKeyboardShortcut');
export const shownMoreSelector = path('setup.shownMore');

export const toggleShowKeyboardShortcut = () => ({
  type: TOGGLE_KEYBOARD_SHORTCUT,
});

export const showKeyboardShortcut = () => ({
  type: SHOW_KEYBOARD_SHORTCUT,
});

export const showMore = () => ({
  type: SHOW_MORE,
});

interface SetupState {
  shownKeyboardShortcut?: boolean;
  shownMore?: boolean;
}

/* istanbul ignore next */
const reducer = (state: SetupState = {}, { type }: AnyAction) => {
  switch (type) {
    case SHOW_KEYBOARD_SHORTCUT:
      return { ...state, shownKeyboardShortcut: true };
    case TOGGLE_KEYBOARD_SHORTCUT:
      return { ...state, shownKeyboardShortcut: !state.shownKeyboardShortcut };
    case SHOW_MORE:
      return { ...state, shownMore: true };
    case SUBMIT_TASK:
      return { ...state, shownMore: false };
    default:
      return state;
  }
};

export default { setup: reducer };
