import React from 'react';
import { connect } from 'react-redux';

import Modal from './Modal';
import { toggleShowKeyboardShortcut, shownKeyboardShortcutSelector } from '../store/reducers/setup';

const connectToRedux = connect(
  state => ({
    open: shownKeyboardShortcutSelector(state),
  }),
  {
    onRequestClose: toggleShowKeyboardShortcut,
  }
);

interface KeyboardShortcutModal {
  open: boolean;
  onRequestClose: Function;
}

const KeyboardShortcutModal: React.FC<KeyboardShortcutModal> = ({ open, onRequestClose }) => (
  <Modal
    key="modal"
    title="KEYBOARD SHORTCUTS"
    open={open}
    onRequestClose={onRequestClose}
    effectName="fadeAndScale"
    style={{ maxWidth: '540px', width: '100%' }}
    testId="keyboard-shortcut-modal"
  >
    <div className="KeyboardShortcut">
      <div className="KeyboardShortcut__left">
        <div>
          <span className="Shortcut">1</span>
          <span>Positive Intent</span>
        </div>
        <div>
          <span className="Shortcut">2</span>
          <span>Negative Intent</span>
        </div>
        <div>
          <span className="Shortcut">3</span>
          <span>Meeting Request</span>
        </div>
        <div>
          <span className="Shortcut">4</span>
          <span>Out of Office</span>
        </div>
        <div>
          <span className="Shortcut">5</span>
          <span>Bounced</span>
        </div>
        <div>
          <span className="Shortcut">6</span>
          <span>Using other solution</span>
        </div>
        <div>
          <span className="Shortcut">7</span>
          <span>Referral</span>
        </div>
        <div>
          <span className="Shortcut">8</span>
          <span>Request for Information</span>
        </div>
        <div>
          <span className="Shortcut">9</span>
          <span>Not the right person</span>
        </div>
      </div>
      <div className="KeyboardShortcut__right">
        <div>
          <span className="Shortcut Shortcut--transparent"></span>
          <span className="Shortcut">0</span>
          <span>Not a good time</span>
        </div>
        <div>
          <span className="Shortcut Shortcut--transparent"></span>
          <span className="Shortcut">q</span>
          <span>Neutral intent</span>
        </div>
        <div>
          <span className="Shortcut Shortcut--transparent"></span>
          <span className="Shortcut">w</span>
          <span>Do not contact</span>
        </div>
        <div>
          <span className="Shortcut Shortcut--transparent"></span>
          <span className="Shortcut">↑</span>
          <span>Select Rule Up</span>
        </div>
        <div>
          <span className="Shortcut Shortcut--transparent"></span>
          <span className="Shortcut">↓</span>
          <span>Select Rule Down</span>
        </div>
        <div>
          <span className="Shortcut Shortcut--transparent"></span>
          <span className="Shortcut">↲</span>
          <span>Continue</span>
        </div>
        <div>
          <span className="Shortcut Shortcut--transparent"></span>
          <span className="Shortcut">n</span>
          <span>Skip</span>
        </div>
        <div>
          <span className="Shortcut">⌘</span>
          <span className="Shortcut">/</span>
          <span>Keyboard Shortcut</span>
        </div>
        <div>
          <span className="Shortcut">⌘</span>
          <span className="Shortcut">0</span>
          <span>Show More Rules</span>
        </div>
      </div>
    </div>
  </Modal>
);

export default connectToRedux(KeyboardShortcutModal);
