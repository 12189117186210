import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import './assets/styles/app.scss';

import Root from './Root';
import * as serviceWorker from './serviceWorker';
import createStore from './store/createStore';

const { store, persistor } = createStore();

const rootNode = document.getElementById('root');

if (process.env.NODE_ENV === 'production') {
  Sentry.init({ dsn: 'https://286e008c5e5444dab6472ace1575d299@sentry.io/1880710' });
}

ReactDOM.render(<Root store={store} persistor={persistor} />, rootNode);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
