import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle, setDisplayName } from 'recompose';

import NavBar from '../../components/NavBar';
import AnnotationSide from '../Dashboard/AnnotationSide';
import EmailSide from '../Dashboard/EmailSide';
import Loading from '../../components/Loading';
import KeyboardShortcutModal from '../../components/KeyboardShortcutModal';

import { getAnnotationTaskWithId } from '../../services/apiCalls/task';
import { currentTaskSelector } from '../../store/reducers/currentTask';
import waitingProps from '../../services/HOC/waitingProps';
import requireUser from '../../services/HOC/requireUser';
import { LooseObject } from '../../types/basic';

const connectToRedux = connect(
  state => ({
    requiredProps: currentTaskSelector(state),
  }),
  {
    onInitTask: getAnnotationTaskWithId,
  }
);

const onLoad = lifecycle<{ onInitTask: Function; taskId: string }, {}>({
  componentDidMount() {
    const { taskId } = this.props;
    this.props.onInitTask(taskId);
  },
});

export interface EnhanceDashboardProps {
  requireProps: LooseObject;
  onInitTask: Function;
}

const enhance = compose<EnhanceDashboardProps, { taskId: string }>(
  setDisplayName('pageTask'),
  requireUser,
  connectToRedux,
  onLoad,
  waitingProps(Loading)
);

const Task = () => (
  <>
    <NavBar key="navbar" />
    <KeyboardShortcutModal key="modal" />
    <div key="annotation" className="container" data-testid="annotation">
      <AnnotationSide />
      <EmailSide />
    </div>
  </>
);

export default enhance(Task);
